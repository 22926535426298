import React, { Component } from "react";
import "../index.css";
import styled from "styled-components";
import MuiWrapper from "../MuiWrapper";
import AppConfig from "../Config";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { Helmet } from "react-helmet";

class Layout extends Component {
  componentDidMount() {
    window.addEventListener("scroll", this._onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this._onScroll);
  }

  render() {
    let {
      location: { pathname }
    } = this.props;
    let passBreakpoint = !!this.passBreakpoint;
    pathname = this._normalizePath(pathname);

    let withPaddingTop = !AppConfig.global.navbarCoverContent;

    if (pathname === "/") {
      withPaddingTop = false;
    }

    return (
      <MuiWrapper>
        <Layout.Wrapper withPaddingTop={withPaddingTop}>
          <Helmet>
            <title>Revtel Tech 忻旅科技</title>
            <meta
              name="description"
              content="Revtel Tech 忻旅科技 | 網站、APP、物聯網開發 | 始於專注，終於優質"
            />
            <link rel="shortcut icon" href="/favicon.ico" />
          </Helmet>

          <Navbar passBreakpoint={passBreakpoint || withPaddingTop} />

          <Layout.Content>{this.props.children}</Layout.Content>

          <Footer pathname={pathname} />
        </Layout.Wrapper>
      </MuiWrapper>
    );
  }

  _normalizePath = path => {
    if (path.slice(0, 2) === "//") {
      path = path.slice(1);
    }

    if (path[path.length - 1] !== "/") {
      path += "/";
    }

    return path;
  };

  _onScroll = e => {
    const breakpoint = 600;
    let scrollTop = document.querySelector("html").scrollTop;

    if (scrollTop > breakpoint) {
      if (!this.passBreakpoint) {
        this.passBreakpoint = true;
        this.forceUpdate();
      }
    } else {
      if (this.passBreakpoint) {
        this.passBreakpoint = false;
        this.forceUpdate();
      }
    }
  };
}

Layout.Wrapper = styled.div`
  min-height: calc(100vh);
  background-color: white;
  padding-top: ${props =>
    props.withPaddingTop ? AppConfig.global.navbarHeight : 0}px;
  display: flex;
  flex-direction: column;
`;

Layout.Content = styled.div`
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

export default Layout;
