import React from "react";
import styled from "styled-components";
import settings from "../../Domain/constants";

const ThemeColor = settings.themeColor || "#ff7054";
const Styled = styled;
const Row = Styled.div`
    display: flex;
    align-items: ${props => props.align || "stretch"};
    justify-content: ${props => props.justify || "flex-start"};
    flex-wrap: ${props => (props.wrap ? "wrap" : "nowrap")};
    ${props => props.extraCss || ""};
`;

class Encoding extends React.Component {
  static Type = Styled.div`
    margin: 6px 12px;
    color: ${props => (props.selected ? ThemeColor : "#ccc")}
  `;

  render() {
    let { selected, onSelect } = this.props;

    return (
      <Row
        align="center"
        justify="center"
        extraCss="margin: 0 auto; padding: 3px;"
      >
        <h4>Encoding: </h4>
        <Encoding.Type
          selected={selected === "json"}
          onClick={() => onSelect("json")}
        >
          json
        </Encoding.Type>
        <Encoding.Type
          selected={selected === "form-data"}
          onClick={() => onSelect("form-data")}
        >
          form-data
        </Encoding.Type>
      </Row>
    );
  }
}

export default Encoding;
