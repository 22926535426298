import React from "react";
import styled from "styled-components";
import Method from "./Method";
import Encoding from "./Encoding";
import settings from "../../Domain/constants";

const ThemeColor = settings.themeColor || "#ff7054";
const Styled = styled;

class Endpoint extends React.Component {
  static Wrapper = Styled.div`
    padding: 10px;
    border-bottom: 1px solid #ccc;
  `;

  static ToggleCollapse = Styled.div`
    width: 60px;
    padding: 5px 10px;
    border-radius: 15px;
    background-color: ${ThemeColor};
    color: white;
    position: absolute;
    top: -15px;
    left: calc(50% - 40px);
    text-align: center;
  `;

  constructor(props) {
    super(props);

    function getUrlParams(path) {
      const pattern = /<([^>]+)>/g;
      return path.match(pattern);
    }

    this.urlParams = getUrlParams(this.props.endpoint.path);

    this.state = {
      open: false,
      fields: {},
      urlFields:
        this.urlParams &&
        this.urlParams.reduce((acc, key) => {
          acc[key] = "";
          return acc;
        }, {}),
      encoding: "json",
      queryParams: ""
    };

    this.inputRefs = {};
  }

  render() {
    let { endpoint, token } = this.props;
    let { open, fields, urlFields, encoding, queryParams } = this.state;

    return (
      <Endpoint.Wrapper>
        <div onClick={() => this.setState({ open: !open })}>
          <h2>{endpoint.path}</h2>
          <h3 style={{ color: "#ccc" }}>{endpoint.summary}</h3>
        </div>
        {open && (
          <div
            style={{
              padding: 15,
              marginTop: 15,
              position: "relative",
              border: `1px solid ${ThemeColor}`,
              borderRadius: 4
            }}
          >
            <Endpoint.ToggleCollapse
              onClick={() => this.setState({ open: false })}
            >
              Close
            </Endpoint.ToggleCollapse>

            <div
              style={{ marginBottom: 10 }}
              dangerouslySetInnerHTML={{ __html: endpoint.html }}
            />

            <Encoding
              selected={encoding}
              onSelect={encoding => this.setState({ encoding })}
            />

            <h3>Url Params: {!this.urlParams && "---"}</h3>
            {this.urlParams && (
              <ul>
                {this.urlParams.map(key => (
                  <li key={key}>
                    <label For={key}>{key.slice(1, -1)}:</label>
                    <input
                      name={key}
                      value={urlFields[key]}
                      onChange={e => {
                        this.setState({
                          urlFields: {
                            ...urlFields,
                            [key]: e.target.value
                          }
                        });
                      }}
                    />
                  </li>
                ))}
              </ul>
            )}

            <h3>
              Query Params:
              <input
                value={queryParams}
                style={{ marginLeft: 15 }}
                onChange={e => {
                  this.setState({
                    queryParams: e.target.value
                  });
                }}
              />
            </h3>

            <h3>Params: {!endpoint.input && "---"}</h3>
            {endpoint.input && (
              <div>
                {endpoint.input.map(({ name: key, type }) => {
                  let inputProps = this._getInputProps(
                    { name: key, type },
                    fields
                  );
                  return (
                    <li key={key}>
                      <label htmlFor={key}>{key}: </label>
                      <input {...inputProps} />
                      <span
                        style={{
                          paddingLeft: 10,
                          color: "#ccc",
                          fontSize: "80%"
                        }}
                      >
                        ({type})
                      </span>
                    </li>
                  );
                })}
              </div>
            )}

            <Method
              method={endpoint.method}
              endpoint={endpoint}
              fields={fields}
              urlParams={this.urlParams}
              queryParams={queryParams}
              urlFields={urlFields}
              token={token}
              encoding={encoding}
              getFiles={this._getInputFiles}
            />
          </div>
        )}
      </Endpoint.Wrapper>
    );
  }

  _getInputProps = ({ name, type }, fields) => {
    let props = {
      name,
      type: "text",
      value: fields[name],
      onChange: e => {
        let value = null;
        if (e.target.type === "checkbox") {
          value = e.target.checked;
        } else if (e.target.type === "number") {
          value = parseInt(e.target.value, 10);
        } else {
          value = e.target.value;
        }

        this.setState({
          fields: {
            ...fields,
            [name]: value
          }
        });
      }
    };

    switch (type) {
      case "number":
        props.type = "number";
        break;

      case "bool":
        delete props.value;
        props.type = "checkbox";
        props.checked = fields[name] || false;
        break;

      case "text":
        if (name === "password" || name === "pass") {
          props.type = "password";
        }
        break;

      case "password":
        props.type = "password";
        break;

      case "file":
        return {
          name,
          type: "file",
          ref: fileInput => {
            this.inputRefs[name] = fileInput;
          }
        };
    }

    return props;
  };

  _getInputFiles = () => {
    return Object.keys(this.inputRefs).reduce((acc, name) => {
      let input = this.inputRefs[name];
      acc[name] = (input.files && input.files[0]) || null;
      return acc;
    }, {});
  };
}

export default Endpoint;
