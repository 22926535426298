import React from "react";
import styled from "styled-components";
import { withPage } from "../../Page";
import Endpoint from "./Endpoint";
import settings from "../../Domain/constants";

const siteName = settings.siteName || "Api Doc";
const ThemeColor = settings.themeColor || "#ff7054";
const Styled = styled;
const Row = Styled.div`
    display: flex;
    align-items: ${props => props.align || "stretch"};
    justify-content: ${props => props.justify || "flex-start"};
    flex-wrap: ${props => (props.wrap ? "wrap" : "nowrap")};
    ${props => props.extraCss || ""};
`;

class App extends React.Component {
  static Wrapper = Styled.div`
        background-color: #e0e0e0;
        padding: 102px 0px 0px 0px;
   `;

  static Navbar = Styled.div`
        background-color: ${ThemeColor};
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw; 
        height: 72px;
        display: flex;
        align-items: center;
        padding: 0px 30px;
        box-shadow: 0 6px 12px rgba(0,0,0,0.16), 0 6px 12px rgba(0,0,0,0.23);
        z-index: 100;
        & > h1 {
            color: white;
        }
    `;

  static Footer = Styled.div`
        margin-top: 30px;
        padding: 60px;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        & > h3 {
            color: #ccc;
        }
    `;

  static WithMinHeight = Styled.div`
        min-height: calc(100vh - 360px);
    `;

  static Content = Styled.div`
        max-width: 800px;
        margin: 0px auto;
        margin-bottom: 20px;
        padding: 15px;
        background-color: white;
        box-shadow: 0 6px 12px rgba(0,0,0,0.16), 0 6px 12px rgba(0,0,0,0.23);
    `;

  constructor(props) {
    super(props);
    this.state = {
      token: {
        type: "Token",
        value: ""
      },
      filter: "",
      auth: true
    };
  }

  render() {
    let { pageContext } = this.props;
    let { token, filter, auth } = this.state;
    let endpoints = pageContext.endpoints.map(endpoint => ({
      ...endpoint.frontmatter,
      method: endpoint.frontmatter.method.toUpperCase(),
      input:
        (endpoint.frontmatter.input &&
          endpoint.frontmatter.input.map(input => {
            let token = input.split(":");
            return {
              name: token[0],
              type: token[1]
            };
          })) ||
        [],
      html: endpoint.html
    }));

    let filteredEndpoints = endpoints
      .sort((a, b) => (a.path > b.path ? 1 : -1))
      .filter(endpoint => {
        if (filter) {
          return (
            endpoint.path
              .toLocaleLowerCase()
              .indexOf(filter.toLocaleLowerCase()) > -1
          );
        }
        return true;
      });

    return (
      <App.Wrapper>
        <App.Navbar>
          <h1>{siteName}</h1>
        </App.Navbar>

        <App.WithMinHeight>
          <App.Content>
            <h2>AUTH</h2>
            <label>
              <input
                placeholder="Token"
                value={token.type}
                onChange={e =>
                  this.setState({ token: { ...token, type: e.target.value } })
                }
              />
              :
            </label>
            <input
              value={token.value}
              onChange={e =>
                this.setState({ token: { ...token, value: e.target.value } })
              }
            />
            <label style={{ marginLeft: 10 }}>
              Enable:
              <input
                type="checkbox"
                checked={auth}
                onChange={e => this.setState({ auth: e.target.checked })}
              />
            </label>
          </App.Content>

          <App.Content>
            <Row align="center">
              <h2 style={{ marginRight: 10 }}>Filter</h2>
              <input
                value={filter}
                onChange={e => this.setState({ filter: e.target.value })}
              />
            </Row>
          </App.Content>

          <App.Content>
            {filteredEndpoints.length === 0 ? (
              <h2>No corresponding API</h2>
            ) : (
              filteredEndpoints.map(endpoint => (
                <Endpoint
                  key={endpoint.path}
                  endpoint={endpoint}
                  token={(auth && this._getFullToken(token)) || null}
                />
              ))
            )}
          </App.Content>
        </App.WithMinHeight>

        <App.Footer>
          <h3>
            Build with ❤️ by{" "}
            <a
              href="https://www.revteltech.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              Revtel
            </a>
          </h3>
        </App.Footer>
      </App.Wrapper>
    );
  }

  _getFullToken = ({ type, value }) => {
    if (!value) {
      return "";
    }
    return `${type ? `${type} ` : ""}${value}`;
  };
}

export default withPage(App);
