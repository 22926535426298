import React, { Component } from "react";
import styled from "styled-components";
import Link from "../Link";

class Footer extends Component {
  render() {
    let { extraStyle = "", pathname } = this.props;

    if (
      pathname === "/cart/" ||
      pathname === "/checkout/" ||
      pathname === "/order/"
    ) {
      return null;
    }

    return (
      <Wrapper extraStyle={extraStyle}>
        <Info>
          <Content main>
            <div className="company-name">Revteltech</div>
            <div className="contact">contact email: contact@revtel.com</div>
          </Content>

          <Content>
            <div className="row-link">
              <div>
                <Link
                  to="#"
                  extraStyle={{
                    marginTop: 20,
                    textDecoration: "underline",
                    color: "#505050"
                  }}
                >
                  FAQ
                </Link>
              </div>
              <div>
                <Link
                  to="#"
                  extraStyle={{
                    marginTop: 20,
                    textDecoration: "underline",
                    color: "#505050"
                  }}
                >
                  隱私權政策
                </Link>
              </div>
              <div>
                <Link
                  to="#"
                  extraStyle={{
                    marginTop: 20,
                    textDecoration: "underline",
                    color: "#505050"
                  }}
                >
                  使用者條款
                </Link>
              </div>
            </div>

            <div className="menu">
              <div>
                <Link
                  to="/"
                  extraStyle={{
                    marginTop: 20,
                    textDecoration: "none",
                    color: "#505050"
                  }}
                >
                  Home
                </Link>
              </div>
              <div>
                <Link
                  to="/paging"
                  extraStyle={{
                    marginTop: 20,
                    textDecoration: "none",
                    color: "#505050"
                  }}
                >
                  Paging
                </Link>
              </div>
              <div>
                <Link
                  to="/products"
                  extraStyle={{
                    marginTop: 20,
                    textDecoration: "none",
                    color: "#505050"
                  }}
                >
                  Product
                </Link>
              </div>
              <div>
                <Link
                  to="/checkout"
                  extraStyle={{
                    marginTop: 20,
                    textDecoration: "none",
                    color: "#505050"
                  }}
                >
                  Checkout
                </Link>
              </div>
              <div>
                <Link
                  to="/cart"
                  extraStyle={{
                    marginTop: 20,
                    textDecoration: "none",
                    color: "#505050"
                  }}
                >
                  Cart
                </Link>
              </div>
            </div>
          </Content>
        </Info>
        <div className="copyright">
          Copyright © 2018 Revteltech Limited. All rights reserved.
        </div>
      </Wrapper>
    );
  }
}

let Wrapper = styled.div`
  background-color: #f8f8f8;
  width: 100vw;
  padding: 40px 10px 80px 10px;
  color: #505050;
  display: flex;
  flex-direction: column;

  & > .copyright {
    font-weight: 500;
    text-align: center;
    font-size: 12px;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
  ${props => props.extraStyle};
`;

let Info = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
`;

let Content = styled.div`
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-grow: ${props => (props.main ? 1 : 0)};
  min-height: ${props => (props.main ? "150px" : "100px")};
  min-width: 300px;

  & > .company-name {
    color: #37c491;
    font-size: 30px;
  }

  & > .contact {
    font-size: 22px;
  }

  & > .title {
    font-size: 15px;
    margin-bottom: 10px;
  }

  & > .menu {
    flex-grow: 1;
  }

  & > .row-link {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;
  }

  @media screen and (max-width: 500px) {
    margin-top: 20px;
    width: 100%;
    min-width: 200px;
  }
`;

export default Footer;
