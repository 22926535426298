import React from "react";
import JsonView from "react-json-tree";
import ApiProxy from "./ApiProxy";
import styled from "styled-components";
import settings from "../../Domain/constants";

const ThemeColor = settings.themeColor || "#ff7054";
const Wrapper = styled.div`
  margin: 15px 0px;

  & button {
    padding: 5px 10px;
  }
`;

class Method extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      response: null,
      error: null,
      sending: false
    };
  }

  render() {
    let { endpoint, method } = this.props;
    let { response, error, sending } = this.state;
    return (
      <Wrapper>
        <button
          onClick={() => this._doAction(method)}
          style={{ color: ThemeColor, marginRight: 10 }}
        >
          Send
        </button>
        <button
          onClick={() => this.setState({ response: null, error: null })}
          style={{ color: "grey" }}
        >
          Clear
        </button>
        {(sending && <h4>Sending...</h4>) || (
          <div>
            <h4>Response:</h4>
            {response && <JsonView data={response} />}
            <h4>Error:</h4>
            {error && <JsonView data={error} />}
          </div>
        )}
      </Wrapper>
    );
  }

  _doAction = method => {
    let {
      endpoint,
      token,
      fields,
      urlFields,
      urlParams,
      encoding,
      getFiles,
      queryParams
    } = this.props;

    let path = endpoint.path;
    if (urlParams) {
      path = urlParams.reduce((acc, key) => {
        return acc.replace(key, urlFields[key]);
      }, path);
    }

    path += queryParams;

    this.setState({ sending: true, response: null, error: null });
    if (method === "GET") {
      ApiProxy.get({
        token,
        path
      })
        .then(response => {
          this.setState({ response, sending: false });
        })
        .catch(error => {
          this.setState({ error, sending: false });
        });
    } else if (method === "POST") {
      ApiProxy.post({
        token,
        path,
        encoding,
        data: fields,
        files: getFiles()
      })
        .then(response => {
          this.setState({ response, sending: false });
        })
        .catch(error => {
          this.setState({ error, sending: false });
        });
    } else if (method === "PUT") {
      ApiProxy.put({
        token,
        path,
        encoding,
        data: fields,
        files: getFiles()
      })
        .then(response => {
          this.setState({ response, sending: false });
        })
        .catch(error => {
          this.setState({ error, sending: false });
        });
    } else if (method === "DELETE") {
      ApiProxy.delete({
        token,
        path
      })
        .then(response => {
          this.setState({ response, sending: false });
        })
        .catch(error => {
          this.setState({ error, sending: false });
        });
    }
  };
}

export default Method;
